import { useEffect, useState } from 'react';
import '../App.css';
import { BloodtypeSharp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
export default function SingleBlog() {

    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    let { id } = useParams();
    console.log(id, "id");

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch(`https://server.growupfitness.com/api/blog/${id}`);
                const result = await response.json();
                if (result.success) {
                    console.log(result);
                    setName(result.data.name)
                    setContent(result.data.content)
                }
            }
            catch (error) {
                console.error('Fetch error:', error.message);
            }
        };
        fetchData();
        setLoading(false)
    }, [id]);

    return (
        <>
            <div style={{ display: "flex", gap: "10px", paddingTop: "10%" }} className="khaobhai">
                <h2 style={{ textAlign: "start", width: "100%" }}>{name}</h2>
                {
                    loading ? <h2 style={{ color: "black" }}>Loading...</h2> : <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: content }} />
                }
            </div>
        </>

    )
}
