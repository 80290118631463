import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import SingleBlog from "../pages/SingleBlog";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IconButton from '@mui/material/IconButton';


function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
        <IconButton onClick={onClick} aria-label="next" style={{ ...style, position: 'absolute', top: '50%', right: '-30px', transform: 'translateY(-50%)' }}>
            <ArrowCircleRightIcon sx={{ color: "#5856d6", fontSize: "30px" }} />
        </IconButton>
    );
}

function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
        <IconButton onClick={onClick} aria-label="next" style={{ ...style, position: 'absolute', top: '50%', left: '-30px', transform: 'translateY(-50%)' }}>
            <ArrowCircleLeftIcon sx={{ color: "#5856d6", fontSize: "30px" }} />
        </IconButton>
    );
}

function Blog() {

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://server.growupfitness.com/api/get/all/blog');
                const result = await response.json();
                if (result.success) {
                    console.log(result);
                    console.log("result :", result.message);
                    setBlogs(result.data)
                }
            }
            catch (error) {
                console.error('Fetch error:', error.message);
            }
        };
        fetchData();
    }, []);

    // console.log(blogs);

    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const handleClick = () => {
        navigate("/SingleBlog")
        document.documentElement.scrollTop = 10;
    }

    return (

        <>
            <div>
                <Slider {...settings} >
                    {blogs.map((elm) => (
                        <div style={{
                            width: { sm: "50px", md: "200px" },
                            // height: { md: "200px" },
                            // padding: "8px",
                            // border: "1px solid gray",
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyItems: "center",
                            background: "gray"
                        }}>
                            <Link to={`/SingleBlog/${elm._id}`} style={{ color: "black", textDecoration: "none", display: "flex", flexDirection: "column", alignItems: "center", justifyItems: "center" }}>
                                <img
                                    src={`https://growupfitness.s3.ap-south-1.amazonaws.com/${elm.image}`}
                                    style={{
                                        width: "70%",
                                        height: "500px",
                                        // height: { sm: "100px", md: "500px", lg: "500px" },
                                        cursor: "pointer",
                                        objectFit: "cover",
                                        padding: "10px",
                                        borderRadius: "20px"
                                    }}
                                    alt=""
                                    onClick={handleClick}
                                />
                            </Link>
                            <h1 style={{ padding: "30px", textDecoration: "none", fontSize: "20px", textAlign: "center" }}>{elm.name}</h1>
                        </div>
                    ))}
                </Slider>
            </div>

        </>
    )
}

export default Blog
