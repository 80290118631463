import Homepage from "./pages/Homepage";
import Policy from "./pages/policy";
import Terms from "./pages/Terms";
import Return from "./pages/return";

const routes = [
    {
      type: "route",
      name: "HomePage",
      key: "home",
      route: "/homepage",
      component: <Homepage />,
    },
    {
      type: "route",
      name: "Policy",
      key: "Policy",
      route: "/policy",
      component: <Policy />,
    },
    {
      type: "route",
      name: "Terms",
      key: "Terms",
      route: "/terms",
      component: <Terms />,
    },
    {
      type: "route",
      name: "return",
      key: "return",
      route: "/return",
      component: <Return />,
    },
]

export default routes;