
 
 import { useEffect, useState } from 'react';
 import '../App.css';
 export default function Privacy() {
 
   const [policy, setPolicy] = useState('');
   const [loading, setLoading] = useState(false);
 
   useEffect(() => {
     setLoading(true)
     fetch(`https://server.growupfitness.com/api/policies/getAllPolicies`).then((res) => res.json()).then((data) => {
       setPolicy(data?.data[2]?.policy);
       setLoading(false);
     })
   }, []);
 
   return (<>
     <div className="khaobhai" >
       {
         loading ? <h2>Loading...</h2> : <div dangerouslySetInnerHTML={{ __html: policy }} />}
     </div>
   </>
 
   )
 }
 