import React, { useState } from "react";
import Header from "../Components/Header";
import Navbaar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Slider from "../Components/Slider/Slider";
import SwiperSlider from "../Components/Slider/Slider";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";


import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import bannar from "../images/Background Image.png";
import {
  AccountBalance,
  Chat,
  CheckCircle,
  Download,
  DownloadDone,
  Star,
  Start,
  TabletMac,
  ThumbUpOffAlt,
} from "@mui/icons-material";
import ReactPlayer from "react-player";
import MobileNavbar from "../Components/MobileNav";
// import SwiperSlider from "../Components/Akslider/Swiper";
import { SwiperSlide } from "swiper/react";
import { Navigate } from "react-router-dom";
import Blog from "../Components/Blog";




const imageArray = [
  {
    img: require("../images/screen2 (2).png"),
  },
  {
    img: require("../images/screen1.png"),
  },

  {
    img: require("../images/Homemobile.png"),
  },
  {
    img: require("../images/screen3.png"),
  },
  {
    img: require("../images/screen4.png"),
  },
];
const SliderImg = [
  {
    img: require("../images/slider1.png"),
  },
  {
    img: require("../images/sliderpro.png"),
  },

  {
    img: require("../images/slider3.png"),
  },
  {
    img: require("../images/slider4.png"),
  },
  {
    img: require("../images/slider5.png"),
  },
];

const Homepage = ({ content }) => {
  const matches = useMediaQuery("(max-width:870px)");
  const [bannerOneIndex, setBannerOneIndex] = useState(0);
  const [activeSlideId, setActiveSlideId] = useState("");
  const handleSlideChange = (swiper) => {
    setBannerOneIndex(swiper.activeIndex);
    const activeSlide = document.querySelector(".swiper-slide-active");
    const activeSlideId = activeSlide.getAttribute("data-_id");
    setActiveSlideId(activeSlideId);
  };


  const [blogs, setBlogs] = useState([]);
  const [blogContent, setBlogContent] = useState('');
  const navigate = useNavigate()





  const styles = {
    paperContainer: {
      // width: "100%",
      height: "115vh",
      //   backgroundRepeat: "no-repeat",
      //   backgroundImage: `${bannar}`,
      width: "100%",
      // height: {xs: "80vh", sm: "60vh", md: "60vh", lg: "60vh"},
      objectFit: "cover",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${bannar})`,
    },
  };


  // useEffect(() => {
  //   const fetchData = async () => {

  //     try {
  //       const response = await fetch('https://devserver.growupfitness.com/api/get/all/blog');

  //       const result = await response.json();
  //       if (result.success) {
  //         console.log(result);
  //         console.log("result :", result.message);
  //         setBlogs(result.data)

  //       }
  //     }
  //     catch (error) {
  //       console.error('Fetch error:', error.message);
  //     }
  //   };
  //   fetchData();

  // }, []);

  // // console.log(blogs);

  // const handleClick = () => {
  //   navigate("/SingleBlog")
  //   document.documentElement.scrollTop = 10;
  // }

  // SwiperCore.use([Navigation]);
  return (
    <div>
      <Header />

      {/* ================================================================ */}

      <Container
        sx={{
          padding: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            textTransform="uppercase"
            fontFamily="Josefin Sans, sans-serif"
            fontWeight="700"
          >
            About Our App
          </Typography>
          <Typography
            variant="caption"
            color="black.main"
            // width="60%"
            fontFamily="Josefin Sans, sans-serif"
            align="center"
            sx={{ width: { xs: "100%", lg: "60%" } }}
          >
            Elevate your fitness business with Grow Up Fitness – the all-in-one
            app designed to help you and your clients achieve your fitness
            goals. Streamline client management, track progress, and inspire
            lasting transformations. It's time to grow stronger, together.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "2%",
            flexDirection: { xs: "column", md: "row", lg: "row" },
          }}
        >
          <Container
            sx={{ width: { xs: "100%", sm: "60%", md: "100%", lg: "50%" } }}
          >
            <Box
              sx={{
                width: { xs: "65%", sm: "65%", md: "50%", lg: "50%" },
                height: { xs: "40vh", sm: "40vh", md: "45rem", lg: "45rem" },
              }}
            >
              <img
                src={require("../images/aboutbanner.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Container>
          <Container
            sx={{
              width: { xs: "100%", lg: "50%" },
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
            }}
          >
            <Card>
              <CardContent sx={{ display: "flex", gap: "2%" }}>
                <CheckCircle sx={{ color: "primary.main" }} fontSize="large" />
                <Box>
                  <Typography
                    variant="h6"
                    fontFamily="Josefin Sans, sans-serif"
                    textTransform="uppercase"
                    fontWeight="600"
                  >
                    Creative design
                  </Typography>
                  <Typography
                    variant="caption"
                    fontFamily="Josefin Sans, sans-serif"
                  >
                    Our innovative app not only streamlines client management
                    but also boasts a stunning, creative design that sets your
                    fitness business apart. Elevate your fitness journey with
                    style
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent sx={{ display: "flex", gap: "2%" }}>
                <CheckCircle sx={{ color: "primary.main" }} fontSize="large" />
                <Box>
                  <Typography
                    variant="h6"
                    fontFamily="Josefin Sans, sans-serif"
                    textTransform="uppercase"
                    fontWeight="600"
                  >
                    Easy to use
                  </Typography>
                  <Typography
                    variant="caption"
                    fontFamily="Josefin Sans, sans-serif"
                  >
                    Our app's user-friendly features make managing clients and
                    workouts a breeze. From seamless scheduling to intuitive
                    progress tracking, achieving your fitness goals has never
                    been easier.
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent sx={{ display: "flex", gap: "2%" }}>
                <CheckCircle sx={{ color: "primary.main" }} fontSize="large" />
                <Box>
                  <Typography
                    variant="h6"
                    fontFamily="Josefin Sans, sans-serif"
                    textTransform="uppercase"
                    fontWeight="600"
                  >
                    Best user experince
                  </Typography>
                  <Typography
                    variant="caption"
                    fontFamily="Josefin Sans, sans-serif"
                  >
                    Elevate your client relationships, streamline workouts, and
                    maximize results effortlessly. Join us for the ultimate
                    fitness journey towards a healthier, happier you.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </Container>
      {/* 
      <Box sx={{position:"relative"}}>
        <img
          src={require("../images/featurebanner.png")}
          style={{ width: "100%", height: "auto" }}
        />

<Container
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position:"absolute"
          }}
        >
          <Typography variant="h3" align="center">
            About Our App
          </Typography>
          <Typography
            variant="caption"
            color="black.main"
            width="60%"
            align="center"
          >
            Elevate your fitness business with Grow Up Fitness – the all-in-one
            app designed to help you and your clients achieve your fitness
            goals. Streamline client management, track progress, and inspire
            lasting transformations. It's time to grow stronger, together.
          </Typography>
        </Container>


      </Box> */}

      <Box
        sx={{
          position: "relative",
          objectFit: "cover",
          display: "flex",
          height: { xs: "200rem", sm: "300vh", lg: "130rem", md: "230vh" },
          // alignItems:"center",
          // justifyContent:"center",
        }}
      >
        <img
          src={require("../images/featurebanner.png")}
          style={{ width: "100%", height: "100%" }}
        />

        <Box
          sx={{
            position: "absolute",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",

            py: "6%",
          }}
        >
          <Typography
            variant="h3"
            fontFamily="Josefin Sans, sans-serif"
            color="white.main"
            textTransform="uppercase"
            fontWeight="700"
          >
            App features
          </Typography>
          <Typography
            variant="caption"
            color="white.main"
            sx={{ width: { xs: "100%", lg: "40%" } }}
            align="center"
            fontFamily="Josefin Sans, sans-serif"
          >
            Discover Our Feature-Packed App! Experience the excellence of our
            app today and revolutionise the way you manage, communicate, and
            stay connected!
          </Typography>

          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              width: { lg: "60%", xs: "100%" },
              py: "6%",
            }}
          >
            <Box height="3rem" width="3rem" display="flex" alignItems="center">
              <img
                src={require("../images/icones/comment.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Typography
              variant="h5"
              color="white.main"
              fontFamily="Josefin Sans, sans-serif"
              textTransform="uppercase"
            >
              Full free chat
            </Typography>
            <Typography
              variant="caption"
              color="white.main"
              sx={{ width: { lg: "100%", xs: "80%" } }}
              align="center"
              fontFamily="Josefin Sans, sans-serif"
            >
              Stay connected with your community and clients effortlessly with
              our built-in chat feature – no additional charges!
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "row" },
            }}
          >
            <Container
              sx={{
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", lg: "flex-end" },
                  gap: "1.5rem",
                  width: "100%",
                  py: "6%",
                }}
              >
                <Box
                  height="3rem"
                  width="3rem"
                  display="flex"
                  alignItems="flex-end"
                >
                  <img
                    src={require("../images/icones/browser.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  fontFamily="Josefin Sans, sans-serif"
                  color="white.main"
                  align="right"
                  textTransform="uppercase"
                >
                  UNLIMITER FEATURES
                </Typography>
                <Typography
                  variant="caption"
                  color="white.main"
                  sx={{ width: "100%" }}
                  align="center"
                  fontFamily="Josefin Sans, sans-serif"
                >
                  Unlock the full potential of our app with unlimited access to
                  powerful features that simplify your tasks.
                </Typography>
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", lg: "flex-end" },
                  gap: "1.5rem",
                  width: "100%",
                  py: "6%",
                }}
              >
                <Box
                  height="3rem"
                  width="3rem"
                  display="flex"
                  alignItems="flex-end"
                >
                  <img
                    src={require("../images/icones/vector.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
                {/* <Chat sx={{ color: "white.main" }} /> */}
                <Typography
                  variant="h5"
                  fontFamily="Josefin Sans, sans-serif"
                  color="white.main"
                  textTransform="uppercase"
                >
                  AWSOME UI DESIGN
                </Typography>
                <Typography
                  fontFamily="Josefin Sans, sans-serif"
                  variant="caption"
                  color="white.main"
                  sx={{ width: "100%" }}
                  align="center"
                >
                  Experience the future of user-friendly design with our
                  cutting-edge AI interface that adapts to your needs.
                </Typography>
              </Box>
            </Container>
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{ width: "25rem", height: { xs: "50rem", lg: "50rem" } }}
              >
                <img
                  src={require("../images/hhhhh.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Container>
            <Container
              sx={{
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", lg: "flex-start" },
                  gap: "1.5rem",
                  width: "100%",
                  py: "6%",
                }}
              >
                <Box
                  height="3rem"
                  width="3rem"
                  display="flex"
                  alignItems="flex-end"
                >
                  <img
                    src={require("../images/icones/cell-phone.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
                <Typography
                  fontFamily="Josefin Sans, sans-serif"
                  variant="h5"
                  color="white.main"
                  textTransform="uppercase"
                >
                  ISO & ANDROIND VERSION
                </Typography>
                <Typography
                  variant="caption"
                  color="white.main"
                  sx={{ width: "100%" }}
                  align="center"
                  fontFamily="Josefin Sans, sans-serif"
                >
                  Our app is available on both iOS and Android, ensuring
                  accessibility for all users.
                </Typography>
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", lg: "flex-start" },
                  gap: "1.5rem",
                  width: "100%",
                  py: "6%",
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    alignItems: { xs: "center", lg: "flex-start" },
                  }}
                >
                  <Box
                    height="3rem"
                    width="3rem"
                    display="flex"
                    sx={{
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <img
                      src={require("../images/icones/eye-scanner 1.png")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>

                <Typography
                  variant="h5"
                  fontFamily="Josefin Sans, sans-serif"
                  color="white.main"
                  textTransform="uppercase"
                >
                  RETINA READY GREAPHICS
                </Typography>
                <Typography
                  variant="caption"
                  color="white.main"
                  sx={{ width: "100%" }}
                  align="center"
                  fontFamily="Josefin Sans, sans-serif"
                >
                  Unleash the full potential of our app with our handy guide on
                  how to use it perfectly. From beginners to pros, everyone can
                  harness its power with ease. Elevate your experience today!
                </Typography>
              </Box>
            </Container>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              width: "80%",
              p: "6%",
            }}
          >
            <Box display="flex" alignItems="flex-start">
              <Box height="3rem" width="3rem">
                <img
                  src={require("../images/icones/male-telemarketer.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Box>

            <Typography
              fontFamily="Josefin Sans, sans-serif"
              variant="h5"
              color="white.main"
              textTransform="uppercase"
            >
              24/7 SUPPORT BY REAL PEPOLE
            </Typography>
            <Typography
              variant="caption"
              color="white.main"
              align="center"
              fontFamily="Josefin Sans, sans-serif"
              sx={{ width: { xs: "100%", lg: "40%" } }}
            >
              We've got your back around the clock! Our dedicated support team
              is ready to assist you whenever you need it.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ height: { xs: "89rem", lg: "160vh" }, width: "100%", py: "2%" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: "3%",
          }}
        >
          <Typography
            variant="h3"
            fontFamily="Josefin Sans, sans-serif"
            align="center"
            textTransform="uppercase"
            fontWeight={700}
          >
            Checkout Our App Interface Look
          </Typography>
          <Typography
            variant="caption"
            color="black.main"
            sx={{ width: { xs: "100%", lg: "60%" } }}
            align="center"
            fontFamily="Josefin Sans, sans-serif"
          >
            Take a sneak peek into the world of sleek design and user-friendly
            navigation. Checkout our app's captivating interface look and
            discover a visually pleasing and intuitive experience like never
            before. Dive in now!"
          </Typography>
        </Box>

        <Container>
          <SwiperSlider items={1}>
            {SliderImg.map((value, index) => (
              <SwiperSlide key={index} onSlideChange={handleSlideChange}>
                <img
                  src={value?.img}
                // style={{ width: "100%", height: "100%" }}
                />
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </Container>
      </Box>


      <Container
        sx={{
          height: { xs: "80rem", sm: "90vh", md: "160vh", lg: "70vh" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5%",
            width: { xs: "100%", lg: "50%" },
            height: { xs: "55vh", lg: "auto" },
          }}
        >
          <Typography
            variant="h3"
            fontFamily="Josefin Sans, sans-serif"
            sx={{ color: "black.main" }}
          >
            Download App Now
          </Typography>
          <Typography
            variant="caption"
            fontFamily="Josefin Sans, sans-serif"
            sx={{ color: "black.main" }}
          >
            Ready to experience the future? Download the app now and unlock a
            world of possibilities at your fingertips. Your journey to seamless
            management and unparalleled convenience begins with a simple click.
            Get started today!
          </Typography>
          <Link to="https://play.google.com/store/apps/details?id=com.growupfitness.management">
            <Box
              sx={{
                width: { xs: "35%", sm: "20%", md: "18%", lg: "20%" },
                height: { xs: "15vh", sm: "15vh", md: "14vh", lg: "auto" },
              }}
            >
              <img
                src={require("../images/playstore.png")}
                style={{ width: "100%" }}
              />
            </Box>
          </Link>

          <Container sx={{ display: "flex", gap: "5%" }}>
            <Card
              sx={{
                backgroundColor: "primary.main",
                width: "33%",
                height: { xs: "13vh", sm: "17vh", md: "34vh", lg: "22vh" },
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "2%",
                boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
            >
              <Download fontSize="large" sx={{ color: "white.main" }} />
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                1432
              </Typography>
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                Download
              </Typography>
            </Card>
            <Card
              sx={{
                backgroundColor: "primary.main",
                width: "33%",
                height: { xs: "13vh", sm: "17vh", md: "34vh", lg: "22vh" },
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "2%",
                boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
            >
              <ThumbUpOffAlt fontSize="large" sx={{ color: "white.main" }} />
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                1432
              </Typography>
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                Like
              </Typography>
            </Card>
            <Card
              sx={{
                backgroundColor: "primary.main",
                width: "33%",
                height: { xs: "13vh", sm: "17vh", md: "34vh", lg: "22vh" },
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "2%",
                boxShadow: " rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
            >
              <Star fontSize="large" sx={{ color: "white.main" }} />
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                1432
              </Typography>
              <Typography
                fontFamily="Josefin Sans, sans-serif"
                variant="h6"
                color="white.main"
              >
                5 Star Rating
              </Typography>
            </Card>
          </Container>
        </Box>
        <Box
          sx={{ width: { xs: "100%", lg: "50%" }, pt: { xs: "10%", lg: "0%" } }}
        >
          <Box
            sx={{
              width: "100%",
              height: { xs: "38vh", sm: "35vh", md: "65vh", lg: "65vh" },
            }}
          >
            <img
              src={require("../images/featuresection.png")}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Box>
      </Container>

      {/* ================================================ */}

      <Box
        sx={{
          height: { xs: "60rem", sm: "74rem", md: "75rem", lg: "100rem" },
          position: "relative",
          objectFit: "cover",
          display: "flex",
          // alignItems:"center",
          // justifyContent:"center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "40rem", sm: "40rem", md: "45rem", lg: "60rem" },
          }}
        >
          <img
            src={require("../images/vidio bannar.png")}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <Container
          sx={{
            right: { lg: "10%" },
            left: { lg: "6%" },
            position: "absolute",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            // width: "100%",
            p: "6%",
          }}
        >
          <Typography
            fontFamily="Josefin Sans, sans-serif"
            variant="h3"
            color="white.main"
            align="center"
          >
            HOW TO USE THE APP PERFECTLY
          </Typography>
          <Typography
            variant="caption"
            color="white.main"
            sx={{ width: { xs: "100%", lg: "60%" } }}
            align="center"
            fontFamily="Josefin Sans, sans-serif"
          >
            Unleash the full potential of our app with our handy guide on how to
            use it perfectly. From beginners to pros, everyone can harness its
            power with ease. Elevate your experience today!
          </Typography>
        </Container>
        <Box
          sx={{
            position: "absolute",
            right: { xs: "10%", lg: "20%" },
            left: { xs: "10%", lg: "20%" },
            top: { xs: "40%", sm: "30%" },
            width: { xs: "80%", lg: "60%" },
            height: { xs: "31rem", sm: "40rem", md: "40rem", lg: "50rem " },
          }}
        >
          <ReactPlayer
            url="https://youtu.be/ekavM69k-8U?si=acuvFabqO-XiUCyV"
            width="100%"
            height="100%"
          // style={{ width: "865px!important", height: "360px !important" }}
          />
        </Box>
      </Box>

      {/* ======================================================== */}

      <Box
        sx={{
          position: "relative",
          objectFit: "cover",
          display: "flex",
          height: { xs: "120rem", sm: "120rem", md: "125vh", lg: "150vh" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={require("../images/Background Image.png")}
          style={{ width: "100%", height: "100%" }}
        />

        <Container
          sx={{
            position: "absolute",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            padding: "2%",
            width: "100%",
          }}
        >
          <Typography
            fontFamily="Josefin Sans, sans-serif"
            variant="h3"
            color="white.main"
            align="center"
            marginTop="40px"
          >
            Our Gym Member Software For you
          </Typography>
          <Typography
            variant="caption"
            color="white.main"
            sx={{ width: { lg: "60%", xs: "100%" } }}
            align="center"
            fontFamily="Josefin Sans, sans-serif"

          >
            Our gym member software is designed with your unique needs in mind.
            Streamline membership management, enhance communication, and elevate
            your fitness facility's performance. Discover the perfect fit for
            your gym today!
          </Typography>

          <>
            {matches ? (
              <>
                <Container>
                  <SwiperSlider items={1}>
                    {imageArray.map((value, index) => (
                      <SwiperSlide
                        key={index}
                        onSlideChange={handleSlideChange}
                      >
                        <img
                          src={value?.img}
                        // style={{ width: "100%", height: "100%" }}
                        />
                      </SwiperSlide>
                    ))}
                  </SwiperSlider>
                </Container>
              </>
            ) : (
              <Container sx={{ display: "flex", padding: "2%", width: "100%" }}>
                {imageArray.map((ele, index) => (
                  <Box
                    key={index}
                    width="100%"
                    sx={{
                      height: {
                        lg: "40rem",
                        xs: "37vh",
                        md: "55vh",
                        margin: "1%",
                        paddingTop:
                          index === 0
                            ? "3rem"
                            : index === 1
                              ? "2rem"
                              : index === 2
                                ? "1rem"
                                : index === 3
                                  ? "2rem"
                                  : index === 4
                                    ? "3rem"
                                    : "1rem",
                      },
                    }}
                  >
                    <img
                      src={ele?.img}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                ))}
              </Container>
            )}
          </>
          <Link to="https://play.google.com/store/apps/details?id=com.growupfitness.customer">
            <Button
              variant="text"
              sx={{
                color: "white.main",
                textDecoration: "underline",
                fontSize: " 1.5rem",
              }}
              fontFamily="Josefin Sans, sans-serif"
            >
              SEE MORE ABOUT APP
            </Button>
          </Link>
        </Container>
      </Box>



      {/* Blog-Section */}


      <Box
        sx={{
          // position: "relative",
          objectFit: "cover",
          display: "flex",
          height: { xs: "400px", sm:"600px", md: "900px" },

        }}
      >
        <Container
          sx={{
            // position: "absolute",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // gap: "10px",
            padding: "2%",
            // width: "100%",
            // height: { xs: "300px", sm: "400px", md: "1000px" }
          }}
        >
          <Typography
            fontFamily="Josefin Sans, sans-serif"
            variant="h2"
            color="black.main"
            align="center"
            padding="40px"
            fontWeight="bold"
          >
            Our Blog
          </Typography>

          <Container sx={{ width: "100%", height: { xs: "100vh", lg: "auto" }, }} >
            <Blog />
          </Container>
        </Container>
      </Box>



    </div >
  );


};

export default Homepage;
