import { createTheme } from "@mui/material";
// import {
//     useFonts,
//     JosefinSans_100Thin,
//     JosefinSans_200ExtraLight,
//     JosefinSans_300Light,
//     JosefinSans_400Regular,
//     JosefinSans_500Medium,
//     JosefinSans_600SemiBold,
//     JosefinSans_700Bold,
//     JosefinSans_100Thin_Italic,
//     JosefinSans_200ExtraLight_Italic,
//     JosefinSans_300Light_Italic,
//     JosefinSans_400Regular_Italic,
//     JosefinSans_500Medium_Italic,
//     JosefinSans_600SemiBold_Italic,
//     JosefinSans_700Bold_Italic,
//   } from '@expo-google-fonts/josefin-sans';


export const customTheme=createTheme({
    palette:{
        primary:{
            // main:'#57A2F9'
            // main:'#795548'
            main:'#95ADFE'
        },
        secondary:{
            main:'#000000'
            // main:'#f5e7e9'
        },
        error:{
            // main:'#000000'
            main:'#f5e7e9'
        },
        info:{
            main:'#7A7A7A'
        },
        danger:{
            main:'#BEE9EA'
        },
        white:{
            main:'#ffffff'
        },
        Black:{
            main:'#232233'
        },

    },
    typography:{
      
        fontFamily:"Josefin Sans, sans-serif",
        caption:{
            fontSize:'1.5rem!important'
        },
        body2:{
            fontSize:'1.8rem!important'
        },
        h6:{
            fontSize:'2rem'
        },
        h5:{
            fontSize:'2.5rem!important'
        },
        h4:{
            fontSize:'3.5rem!important'
        },
        h2:{
            fontSize:'4rem'
        },
        h1:{
            fontSize:'5rem'
        },
    },

    // breakpoints: {
    //     values: {
    //       xs: 0,
    //       sm: 480,
    //       md: 870,
    //       lg: 1200,
    //       xl: 1536,
    //     },
    //   },
})