import {
  Call,
  ChevronRight,
  Copyright,
  Email,
  Facebook,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';
// import XIcon from '@mui/icons-material/X';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



const Footer = () => {

  const [email, setEmail] = useState('');
  const [sms, setSms] = useState('');
  const navigate = useNavigate()


  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      const response = await fetch('https://devserver.growupfitness.com/api/request/for/help/user/63caa513b594f927322d4042', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment: "email@gmail.com" }),
      });

      const result = await response.json();
      if (result.success) {
        toast.success(result.message);
        console.log(result.message);
        setSms("result.message")
        setEmail(" ")
      } else {
        toast.error(result.message);
        console.log(result.message);
        setSms(result.message)
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const handleClick = () => {
    // navigate("/policy")
    document.documentElement.scrollTop = 10;
  }

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "15vh",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: { xs: "23 vh", sm: "17vh", md: "20vh", lg: "20vh" },
            backgroundColor: "white.main",
            flexWrap: { xs: "wrap", lg: "noWrap" },
            // p: "6rem 0rem 0rem",
            position: "absolute",
            top: "30%",
            width: { xs: "93%", md: "93%", lg: "100%" },
            borderRadius: "18px",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              height: { xs: "8vh", md: "10vh" },
              width: { xs: "68%", md: "30%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                backgroundColor: "primary.main",

                width: { xs: "17%", sm: "10%", md: "14%", lg: "17%" },
                height: { xs: "4vh", md: "6vh", lg: "6rem" },
                borderRadius: "50%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Email fontSize="large" sx={{ color: "white.main" }} />
            </Box>
            <Typography variant="caption" fontFamily="Josefin Sans, sans-serif">
            Fitziila.mail@gmail.com
            </Typography>
          </Box>

          <Box
            sx={{
              height: { xs: "8vh", md: "10vh" },
              width: { xs: "63%", md: "40%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5%",
              flexDirection: { xs: "column", md: "row" },
              borderLeft: { xs: 0, md: 0, lg: 2 },
              borderTop: { xs: 2, md: 2, lg: 0 },
              // padding: { xs: "10px", lg: "5%" },
            }}
          >
            <Box
              sx={{
                backgroundColor: "primary.main",
                // width: { xs: "20%", md: "17%", lg: "14%" },
                // height: { xs: "6vh", md: "6vh", lg: "10vh" },
                width: { xs: "17%", sm: "10%", md: "14%", lg: "14%" },
                height: { xs: "4vh", md: "6vh", lg: "6rem" },

                borderRadius: "50%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Call fontSize="large" sx={{ color: "white.main" }} />
            </Box>
            <Typography variant="caption" fontFamily="Josefin Sans, sans-serif">
              +91 90740 76772
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        width="100%"
        sx={{
          backgroundColor: "Black.main",
          height: { md: "68vh", lg: "47rem" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "45vh",

            flexWrap: { xs: "wrap", md: "nowrap", lg: "noWrap" },
            pt: { xs: "5rem", md: "6rem", lg: "8rem" },
          }}
        >
          <Container
            component="main"
            sx={{ marginTop: { xs: "6rem", md: "16px", lg: "16px" }, mb: 2 }}
            maxWidth="sm"
          >
            <Box
              sx={{
                height: { xs: "8vh", md: "10vh" },
                width: { xs: "20%", md: "30%" },
              }}
            >
              <img
                src={require("../images/logo.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                fontWeight="medium"
                font="Josefin Sans, sans-serif"
                // fontFamily="Josefin Sans, sans-serif"
                sx={{ color: "white.main", padding: "2%" }}
              >
                At Grow Up Fitness, we're dedicated to helping you and your
                clients thrive on your fitness journey.
              </Typography>
            </Box>
            <Box
              // sx={{
              //   display: "flex",
              //   justifyContent: "space-around",
              //   width: "100%",
              //   padding: "5%",
              //   color: "white.main",
              // }}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                padding: "5%",
                color: "white.main",
                "& > *": {
                  paddingRight: { lg: "3rem", xs: "5rem" },
                  borderRight: "1px solid white",
                  marginRight: "10px",
                  "&:last-child": {
                    borderRight: "none",
                    marginRight: "0",
                  },
                },
              }}
            >
              <Link to="https://www.facebook.com/profile.php?id=100054586624095&mibextid=ZbWKwL"><Facebook fontSize="large" sx={{ color: "white.main" }} /></Link>
              <Link to="https://www.instagram.com/grow_up_fitness?igsh=MTA3OG9vMnRpMHAyNw=="><Instagram fontSize="large" sx={{ color: "white.main" }} /></Link>
              <Link to="https://x.com/growupfitness2?t=ijjrEvMJV6OuJ1acXZ_7WQ&s=09"><i class="fa-brands fa-x-twitter" style={{ fontSize: "20px", color: "white" }}></i></Link>
              {/* <Link to="https://x.com/growupfitness2?t=ijjrEvMJV6OuJ1acXZ_7WQ&s=09"><TwitterIcon fontSize="large" sx={{ color: "white.main" }} /></Link> */}
              <Link to="https://www.youtube.com/channel/UCx21aPpw01J_x-oLnMm1atQ"><YouTube fontSize="large" sx={{ color: "white.main" }} /></Link>
            </Box>
          </Container>

          <Container
            component="main"
            sx={{
              mt: 2,
              mb: 2,
              gap: { xs: "3%", md: "5%", lg: "3%" },
              display: "flex",
              flexDirection: "column",
            }}
            maxWidth="md"
          >
            <Typography
              variant="h5"
              fontWeight="medium"
              sx={{ color: "white.main" }}
            >
              Policy
            </Typography>


            <Link to="/terms" onClick={handleClick}>
              <Typography
                variant="h6"
                fontWeight="medium"
                sx={{ color: "white.main" }}
              >
                Terms & Condition
              </Typography>
            </Link>

            <Link to="/policy" onClick={handleClick}>
              <Typography

                variant="h6"
                fontWeight="medium"
                sx={{ color: "white.main" }}
              >
                Privacy Policy
              </Typography>

            </Link>
            <Link to="/return" onClick={handleClick}>
              <Typography
                variant="h6"
                fontWeight="medium"
                sx={{ color: "white.main" }}
              >
                Return Policy
              </Typography>
            </Link>

          </Container>


          <Container
            component="main"
            sx={{
              mt: 2,
              mb: 2,
              gap: { xs: "10%", md: "10%", lg: "10%" },
              display: "flex",
              flexDirection: "column",
            }}
            maxWidth="sm"
          >
            <Typography variant="h5" sx={{ color: "white.main" }}>
              News Letter
            </Typography>
            <Typography
              variant="h6"
              fontWeight="medium"
              sx={{ color: "white.main" }}
            >
              Subscribe our newsletter to get our latest update & news
            </Typography>

            <form>
              <TextField
                fullWidth
                required
                // autoComplete="off"
                variant="outlined"
                placeholder="Your email address"
                name="name"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: { lg: "50px", xs: "40px" },
                    fontSize: "1.5rem!important",
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "error.main",
                    color: "secondary.main",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    paddingRight: ".2rem",
                    borderBottom: "none!important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                  "&.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    borderBottom: "none !important ",
                    // padding: "1rem",
                    // height: 45,
                    fontSize: "1.5rem!important",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" backgroundColor="primary.main">
                      <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon />}
                        sx={{
                          p: "1rem",
                        }}
                      >
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            {/* <TextField
            variant="outlined"
            placeholder="Your email address"
            sx={{
              "& ..css-1dninbl-MuiFormControl-root-MuiTextField-root .MuiInputBase-root": {
                height: 47,
                border: "none",
                outline: "none",
                borderColor: "GrayText",
                bgcolor: "#E8E8E8",
                color: "secondary.main",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                border: "none",
              },
            }}
            // size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Send sx={{ color: "primary.main" }} />
                </InputAdornment>
              ),
            }} /> */}
          </Container>
        </Container>

        <Container>
          <hr />
          <Typography
            variant="caption"
            fontWeight="medium"
            sx={{ color: "white.main" }}
            align="center"
            p="2%"
            fontStyle=" Josefin Sans"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Copyright fontSize="large" /> &nbsp; Copyright 2023 .Growupfitness. All Right
            Reserved.
          </Typography>
        </Container>
      </Box>
    </>
  )
}

export default Footer;
