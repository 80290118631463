import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Container,
  Typography,
  Icon,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AutoAwesomeMotion,
  Close,
  CollectionsBookmark,
  ErrorOutline,
  Feedback,
  Filter,
  Home,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const MobileNavbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const [menu, setMenu] = useState("HOME");



  // const handleClick = () => {
  //   // Scroll to a specific position
  //   document.documentElement.scrollTop = 800;
  //   document.scrollingElement.scrollTop = 800;
  //   setMenu("About");

  //   // Close the drawer
  //   toggleDrawer(false);
  // };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Container
            style={{
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "6rem",
                marginTop: "10%",
                display: "flex",
                justifyContent: "space-around",
                boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: "12px",
                backgroundColor: "white.main",
              }}
            >
              <Box
                width="100%"
                display="flex"
                justify-content="center"
                padding="1%"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  width="100%"
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      // color="inherit"
                      aria-label="menu"
                      onClick={toggleDrawer(true)}
                    >
                      <MenuIcon fontSize="large" />
                    </IconButton>
                  </Toolbar>
                  <Box
                    width="35%"
                    height="12rem"
                    margin="1%"
                    sx={{
                      backgroundColor: "white!important",
                      borderRadius: "12px",
                    }}
                  >
                    <Box
                      justifyContent="center"
                      p="8%"
                      alignItems="center"
                      display="flex"
                      width="90%"
                      height="9rem"
                    >
                      <img
                        src={require("../images/logo.png")}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  </Box>

                  <Button
                    align="center"
                    display="flex"
                    sx={{
                      color: "white.main",
                      backgroundColor: "primary.main",
                    }}
                    fontWeight="medium"
                    variant="contained"
                  >
                    DOWNLOAD
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: { width: "50%" },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box display="flex" justifyContent="end" padding="1%">
          <Close fontSize="large" onClick={toggleDrawer(false)} />
        </Box>

        <Box>
          <Typography variant="h5" color="primary.main" align="center">
            GrowUp Fitness
          </Typography>
        </Box>

        <List>
          <Link to="/homepage" disablePadding style={{ textDecoration: 'none' }} >
            <ListItemButton onClick={toggleDrawer(false)}>
              <ListItemIcon>
                <Home fontSize="large" />
              </ListItemIcon>
              <Typography variant="h6" style={{ textDecoration: "none" }}>Home</Typography>
            </ListItemButton>
          </Link>

          <Link to="/About" disablePadding style={{ textDecoration: 'none' }} >
            <ListItemButton onClick={() => {
              toggleDrawer(false);
              document.documentElement.scrollTop = 800;
              document.scrollingElement.scrollTop = 800;
              setMenu("About");

            }}>
              <ListItemIcon>
                <ErrorOutline fontSize="large" />
              </ListItemIcon>
              <Typography variant="h6">About</Typography>
            </ListItemButton>

          </Link>


          <Link to="/FEATURES" disablePadding style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={() => {
              document.documentElement.scrollTop = 1500;
              document.scrollingElement.scrollTop = 1500;
              setMenu("About");
              toggleDrawer(false);
            }}>
              <ListItemIcon>
                <AutoAwesomeMotion fontSize="large" />
              </ListItemIcon>
              <Typography variant="h6">FEATURES</Typography>
            </ListItemButton>
          </Link>

          <Link to="/SCREENSHOT" disablePadding style={{ textDecoration: 'none' }}>
            <ListItemButton onClick={() => {
              document.documentElement.scrollTop = 2800;
              document.scrollingElement.scrollTop = 2800;
              setMenu("About");
              toggleDrawer(false);
            }}>
              <ListItemIcon>
                <Filter fontSize="large" />
              </ListItemIcon>
              <Typography variant="h6">SCREENSHOT</Typography>
            </ListItemButton>
          </Link>

          <Link to="/BLOG" disablePadding style={{ textDecoration: 'none' }} >
            <ListItemButton onClick={() => {
              document.documentElement.scrollTop = 6300;
              document.scrollingElement.scrollTop = 6300;
              toggleDrawer(false);
              setMenu("About");

            }}>
              <ListItemIcon>
                <Feedback fontSize="large" />
              </ListItemIcon>
              <Typography variant="h6">BLOG</Typography>
            </ListItemButton>
          </Link>

        </List>
      </Drawer>
    </div>
  );
};

export default MobileNavbar;
