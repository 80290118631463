import {
  Call,
  Email,
  Facebook,
  Instagram,
  Twitter,
  // XIcon,
  YouTube,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import XIcon from '@mui/icons-material/X';

const Navbar = () => {
  const [menu, setMenu] = useState("HOME");
  const navigate = useNavigate();


  return (
    <>
      <AppBar
        style={{
          background: "transparent",
          boxShadow: "none",
          position: "absolute",
          height: "25vh",
          justifyContent: "center",
          //   alignItems:"center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10%",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "white.main",
                  fontFamily: "josefin-sans",
                  display: "flex",
                  gap: "5%",
                  alignItems: "center",

                }}
              >
                <Email fontSize="medium" /> Fitziila.mail@gmail.com
              </Typography>
              <Typography
                variant="caption"
                sx={{

                  color: "white.main",
                  fontFamily: "josefin-sans",
                  display: "flex",
                  gap: "10%",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Call fontSize="medium" />9074076772
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "20%",
                padding: "1%",
                alignItems: "center"
              }}
            >
              <Link to="https://www.facebook.com/profile.php?id=100054586624095&mibextid=ZbWKwL"><Facebook fontSize="large" sx={{ color: "white.main" }} /></Link>
              <Link to="https://www.instagram.com/grow_up_fitness?igsh=MTA3OG9vMnRpMHAyNw=="><Instagram fontSize="large" sx={{ color: "white.main" }} /></Link>
              <Link to="https://x.com/growupfitness2?t=ijjrEvMJV6OuJ1acXZ_7WQ&s=09"><i class="fa-brands fa-x-twitter" style={{ fontSize: "20px", color: "white" }}></i></Link>
              {/* <Link to="https://x.com/growupfitness2?t=ijjrEvMJV6OuJ1acXZ_7WQ&s=09"><Twitter fontSize="large" sx={{ color: "white.main" }} /></Link> */}
              <Link to="https://www.youtube.com/channel/UCx21aPpw01J_x-oLnMm1atQ"><YouTube fontSize="large" sx={{ color: "white.main" }} /></Link>
            </Box>
          </Box>
        </Container>
        <Toolbar>
          <Container
            style={{
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "6rem",
                display: "flex",
                justifyContent: "space-around",
                boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: "12px",
                backgroundColor: "white.main",
              }}
            >
              <Box
                width="100%"
                display="flex"
                justify-content="center"
                padding="1%"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  width="100%"
                >
                  <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    fontWeight="medium"
                    fontFamily="Josefin Sans, sans-serif"
                    sx={{
                      color: "black",
                      color: menu === "Home" ? "primary.main" : "black.main",
                      textDecoration: menu === "Home" ? "underline" : "none",
                      cursor: "pointer",
                      ":hover": {
                        color: "primary.main",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      setMenu("Home");
                      navigate("/");

                    }}
                  >
                    HOME
                  </Typography>
                  <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    fontWeight="medium"
                    fontFamily="Josefin Sans, sans-serif"
                    sx={{
                      color: "black",
                      color: menu === "ABOUT" ? "primary.main" : "black.main",
                      textDecoration: menu === "ABOUT" ? "underline" : "none",
                      cursor: "pointer",
                      ":hover": {
                        color: "primary.main",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      document.documentElement.scrollTop = 800;
                      document.scrollingElement.scrollTop = 800;
                      setMenu("ABOUT");
                      // navigate("/");
                    }}
                  >
                    ABOUT
                  </Typography>
                  <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    fontWeight="medium"
                    fontFamily="Josefin Sans, sans-serif"

                    sx={{
                      color: "black",
                      color:
                        menu === "FEATURES" ? "primary.main" : "black.main",
                      textDecoration:
                        menu === "FEATURES" ? "underline" : "none",
                      cursor: "pointer",
                      ":hover": {
                        color: "primary.main",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      document.documentElement.scrollTop = 1500;
                      document.scrollingElement.scrollTop = 1500;
                      setMenu("FEATURES");
                      // navigate("/");
                    }}
                  >
                    FEATURES
                  </Typography>

                  <Box

                    width="18%"
                    height="16vh"
                    margin="1%"
                    sx={{
                      backgroundColor: "white!important",
                      borderRadius: "12px",
                    }}
                  >
                    <Box
                      justifyContent="center"
                      p="8%"
                      alignItems="center"
                      display="flex"
                      sx={{
                        width: {}
                      }}
                    >
                      <img
                        src={require("../images/logo.png")}
                        style={{ width: "50%", height: "100%" }}
                      />
                    </Box>
                  </Box>

                  {/* <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    fontWeight="medium"
                    sx={{
                      color: "black",
                      // color: menu === "About Us" ? "hover.main" : "white.main",
                      cursor: "pointer",

                      ":hover": {
                        color: "hover.main",
                      },
                    }}
                    //   onClick={() => {
                    //     handleMenu("About Us");
                    //     navigate("/aboutpage");
                    //   }}
                  >
                    About Us
                  </Typography> */}
                  <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    fontWeight="medium"
                    fontFamily="Josefin Sans, sans-serif"
                    sx={{
                      color: "black",
                      color:
                        menu === "SCREENSHOT" ? "primary.main" : "black.main",
                      textDecoration:
                        menu === "SCREENSHOT" ? "underline" : "none",
                      cursor: "pointer",
                      ":hover": {
                        color: "primary.main",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      document.documentElement.scrollTop = 2800;
                      document.scrollingElement.scrollTop = 2800;
                      setMenu("SCREENSHOT");
                      // navigate("/");
                    }}
                  >
                    SCREENSHOT
                  </Typography>

                  <Typography
                    align="center"
                    display="flex"
                    variant="h6"
                    color="black"
                    // bgcolor="#F32D2B"
                    fontWeight="medium"
                    fontFamily="Josefin Sans, sans-serif"
                    sx={{
                      color: "black",
                      color:
                        menu === "BLOG" ? "primary.main" : "black.main",
                      textDecoration:
                        menu === "BLOG" ? "underline" : "none",
                      cursor: "pointer",
                      ":hover": {
                        color: "primary.main",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      document.documentElement.scrollTop = 6300;
                      document.scrollingElement.scrollTop = 6300;
                      setMenu("BLOG");
                      // navigate("/");
                    }}

                  >
                    BLOG
                  </Typography>
                  <Link to="https://play.google.com/store/apps/details?id=com.growupfitness.customer">
                    <Button
                      align="center"
                      display="flex"
                      sx={{
                        color: "white.main",
                        backgroundColor: "primary.main",
                        fontSize: "1.5rem",
                      }}
                      fontWeight="large"


                      variant="contained"
                    //   sx={{
                    //     color:
                    //       menu === "Login/Sign Up" ? "hover.main" : "white.main",
                    //     cursor: "pointer",
                    //     ":hover": {
                    //       color: "hover.main",
                    //     },
                    //   }}
                    //   onClick={() => {
                    //     handleMenu("Login/Sign Up");
                    //     navigate("/loginpage");
                    //   }}
                    >
                      DOWNLOAD
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
