// import React from "react";

// import { Box, useMediaQuery } from "@mui/material";

// import Navbar from "../Navbar";

// import Footer from "../Footer";
// import MobileNavbar from "../MobileNav";



// const Layout = ({ children }) => {
//   const matches = useMediaQuery("(max-width:870px)");
//   return (
//     <>
//     {matches ?  <MobileNavbar/> :  <Navbar />}
     
//       <Box className={"appContainer_from_root"}>
     
//         {children}
//       </Box>
//       {/* {matches ? <BottomNavigationBar /> : <Footer />} */}
//       <Footer />
//     </>
//   );
// };

// export default Layout;




import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer";
import MobileNavbar from "../MobileNav";
// import BottomNavigationBar from "../BottomNavigationBar"; // Uncomment this line if you have a BottomNavigationBar component

const Layout = ({ children }) => {
  const matches = useMediaQuery("(max-width:870px)");

  return (
    <>
      {matches ? <MobileNavbar /> : <Navbar />}
  
      <Box className={"appContainer_from_root"}>
        {children}
      </Box>

      {/* {matches ? <BottomNavigationBar /> : <Footer />} */}
   
      
      <Footer />
    </>
  );
};

export default Layout;
