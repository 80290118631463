import { Box, Container, Typography } from "@mui/material";
import React from "react";
import bannar from "../images/Background Image.png";
import { Link, useParams } from "react-router-dom";

const Header = () => {
  const styles = {
    paperContainer: {
      width: "100%",
      objectFit: "cover",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${bannar})`,
    },
  };

  return (
    <>
      <Box style={styles.paperContainer} sx={{ height: { xs: "120rem", sm: "110rem", md: "74rem", lg: "130vh" } }}>
        <img
          src={require("../images/Background Image.png")}
          style={{ width: "100%", height: "100%" }}
        />
        <Container
          sx={{
            position: "absolute",
            justifyItems: "center",
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            marginTop: { xs: "20%", sm: "5%", md: "10%", lg: "10%" },
            // width: "36%"

          }}
        >
          <Container sx={{ width: { xs: "100%", lg: "50%" } }}>
            <Box
              sx={{
                border: 18,
                borderColor: "#DCF5A7",
                height: { xs: "36rem", sm: "40rem", md: "50vh", lg: "55vh" },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white.main",
                  height: { xs: "29rem", sm: "29rem", md: "37vh", lg: "30rem" },
                  position: "absolute",
                  width: { xs: "100%", sm: "93%", md: "100%", lg: "100%" },
                  right: { xs: "5%", lg: "9%" },
                  top: "5%",
                  padding: "5%",
                  gap: { xs: "3%", lg: "9%" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={600}
                  sx={{ color: "primary.main" }}
                >
                  One App, Countless Possibilities
                </Typography>
                <Typography variant="caption" sx={{ color: "black.main" }}>
                  Discover how a single app can transform your management
                  approach and drive remarkable results
                </Typography>

                <Typography variant="h5" sx={{ color: "black.main" }}>
                  Download App Now
                </Typography>
                <Link to="https://play.google.com/store/apps/details?id=com.growupfitness.management">

                  <Box sx={{ width: "30%", height: "auto" }}>
                    <img
                      src={require("../images/playstore.png")}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Link>
              </Box>
            </Box>
          </Container>
          <Container
            sx={{ width: { xs: "100%", lg: "50%" }, display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ width: "50%", marginTop: "50px", height: { xs: "45rem", sm: "48rem", md: "42rem", lg: "100%" } }}>
              <img
                src={require("../images/headerbanner.png")}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
};

export default Header;
