// import logo from './logo.svg';
// import './App.css';
// import Homepage from './pages/Homepage'

import { ThemeProvider } from "@emotion/react";

import Layout from "./Components/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import { Navigate, Route, Routes } from "react-router-dom";
import { customTheme } from "./utils/customTheme";
import routes from "./routes";
import SingleBlog from "./pages/SingleBlog";


function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={customTheme}>
      <Layout>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/homepage" />} />
          <Route path="/SingleBlog/:id" element={<SingleBlog />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
